import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, FreeMode, Thumbs, EffectFade } from "swiper";
import TrackVisibility from "react-on-screen";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
const IntroAreaOne = () => {
  return (
    <div
      className="intro-area-2 bg-overlay-2 space-bottom"
      style={{ backgroundImage: "url(assets/img/intro/bg-2.png)" }}
    >
      {/* fact area start */}
      <div className="fact-area">
        <div className="container">
          <div className="row gy-4 justify-content-center">
            <div className="col-lg-3 col-sm-6">
              <div className="single-fact-wrap">
                <div className="thumb">
                  <img src="assets/img/fact/1.png" alt="Fixturbo" />
                </div>
                <div className="details">
                  <h2>
                    <TrackVisibility once>
                      {({ isVisible }) =>
                        isVisible && (
                          <span className="counter-number">
                            <CountUp delay={0} start={0} end={2} />
                            K+
                          </span>
                        )
                      }
                    </TrackVisibility>
                  </h2>
                  <p>Products In Stock</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-fact-wrap">
                <div className="thumb">
                  <img src="assets/img/fact/2.png" alt="Fixturbo" />
                </div>
                <div className="details">
                  <h2>
                    <TrackVisibility once>
                      {({ isVisible }) =>
                        isVisible && (
                          <span className="counter-number">
                            <CountUp delay={0} start={0} end={3} />
                            M+
                          </span>
                        )
                      }
                    </TrackVisibility>
                  </h2>
                  <p>Happy Clients</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-fact-wrap">
                <div className="thumb">
                  <img src="assets/img/fact/3.png" alt="Fixturbo" />
                </div>
                <div className="details">
                  <h2>
                    <TrackVisibility once>
                      {({ isVisible }) =>
                        isVisible && (
                          <span className="counter-number">
                            <CountUp delay={0} start={0} end={255} />+
                          </span>
                        )
                      }
                    </TrackVisibility>
                  </h2>
                  <p>Professional Exparts</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-fact-wrap">
                <div className="thumb">
                  <img src="assets/img/fact/4.png" alt="Fixturbo" />
                </div>
                <div className="details">
                  <h2>
                    <TrackVisibility once>
                      {({ isVisible }) =>
                        isVisible && (
                          <span className="counter-number">
                            <CountUp delay={0} start={0} end={3} />
                            K+
                          </span>
                        )
                      }
                    </TrackVisibility>
                  </h2>
                  <p>Win Awards</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* fact area end */}
      {/* intro area start */}
  
    </div>
  );
};

export default IntroAreaOne;
