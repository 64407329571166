import { BrowserRouter, Route, Routes } from "react-router-dom";
import RouteScrollToTop from "./helper/RouteScrollToTop";
import ScrollToTop from "react-scroll-to-top";

import HomePageThree from "./pages/HomePageThree";


function App() {
  return (
    <BrowserRouter>
      <RouteScrollToTop />
      <ScrollToTop smooth color="#E8092E" />
      <Routes>
       
        <Route exact path="/" element={<HomePageThree />} />
       
      </Routes>
    </BrowserRouter>
  );
}

export default App;
