import React from "react";

const AboutThree = () => {
  return (
    <div className="about-area-1 space" id="about">
      <div className="about1-shape-img shape-mockup">
        <img
          className="about1-shape-img-1 spin"
          src="assets/img/normal/about_shape1-2.svg"
          alt="DentProfessionalSolution"
        />
        <img
          className="about1-shape-img-2 spin2"
          src="assets/img/normal/about_shape1-1.svg"
          alt="DentProfessionalSolution"
        />
      </div>
      <div className="container">
        <div className="row gx-60 align-items-center flex-row-reverse">
          <div className="col-xl-7 text-xl-center">
            <div className="about-thumb3 mb-40 mb-xl-0">
              <div className="about-img-1">
                <img src="assets/img/normal/wcu-3.png" alt="DentProfessionalSolution" />
              </div>
            </div>
          </div>
          <div className="col-xl-5">
            <div className="about-content-wrap">
              <div className="title-area me-xl-5 mb-30">
                <span className="sub-title">About Us</span>
                <h2 className="sec-title">Our Best Advantages</h2>
                <p className="sec-text">
                At Dent Professional Solution, we're not just about fixing dents; we're dedicated to transforming your vehicle into a masterpiece on wheels. Our commitment to excellence is reflected in every aspect of our service, ensuring that your experience with us is nothing short of exceptional.
                </p>
              </div>
              <div className="row gy-4 justify-content-md-between justify-content-end align-items-center flex-row-reverse">
                <div className="col-md-auto">
                  <div className="checklist style2">
                    <ul>
                      <li>
                        <i className="fas fa-check-double" />
                        Low Price Guarantee
                      </li>
                      <li>
                        <i className="fas fa-check-double" />
                        Trained Technicians
                      </li>
                      <li>
                        <i className="fas fa-check-double" />
                        Mobile Dent Repair
                      </li>
                      <li>
                        <i className="fas fa-check-double" />
                        Automated testing lanes
                      </li>
                      <li>
                        <i className="fas fa-check-double" />
                        Body shop
                      </li>
                      <li>
                        <i className="fas fa-check-double" />
                        Paint Correction
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-auto col-lg-6">
                  <div className="checklist style2">
                    <ul>
                      <li>
                        <i className="fas fa-check-double" />
                        Quick Service Times
                      </li>
                      <li>
                        <i className="fas fa-check-double" />
                        Free Trade Appraisal
                      </li>
                      <li>
                        <i className="fas fa-check-double" />
                        Genuine spare parts
                      </li>
                      <li>
                        <i className="fas fa-check-double" />
                        Unbeatable savings!
                      </li>
                      <li>
                        <i className="fas fa-check-double" />
                        Ceramic coating
                      </li>
                      <li>
                        <i className="fas fa-check-double" />
                        Lease returns and Mobile unit
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutThree;
