import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";

const HeaderThree = () => {
  const [active, setActive] = useState(false);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    var offCanvasNav = document.getElementById("offcanvas-navigation");
    var offCanvasNavSubMenu = offCanvasNav.querySelectorAll(".sub-menu");

    for (let i = 0; i < offCanvasNavSubMenu.length; i++) {
      offCanvasNavSubMenu[i].insertAdjacentHTML(
        "beforebegin",
        "<span class='mean-expand-class'>+</span>"
      );
    }

    var menuExpand = offCanvasNav.querySelectorAll(".mean-expand-class");
    var numMenuExpand = menuExpand.length;

    function sideMenuExpand() {
      if (this.parentElement.classList.contains("active") === true) {
        this.parentElement.classList.remove("active");
      } else {
        for (let i = 0; i < numMenuExpand; i++) {
          menuExpand[i].parentElement.classList.remove("active");
        }
        this.parentElement.classList.add("active");
      }
    }

    for (let i = 0; i < numMenuExpand; i++) {
      menuExpand[i].addEventListener("click", sideMenuExpand);
    }
    window.onscroll = () => {
      if (window.pageYOffset < 150) {
        setScroll(false);
      } else if (window.pageYOffset > 150) {
        setScroll(true);
      }
      return () => (window.onscroll = null);
    };
  }, []);

  const mobileMenu = () => {
    setActive(!active);
  };
  return (
    <header className="nav-header header-layout1">
      <div className="header-top">
        <div className="container">
          <div className="row justify-content-center justify-content-lg-between align-items-center gy-2">
            <div className="col-auto d-none d-lg-block">
              <div className="header-links">
                <ul>
                  <li>
                    <i className="fas fa-envelope" />
                    <a href="mailto:info@dentprofessionalsolution.com">
                      info@dentprofessionalsolution.com
                    </a>
                  </li>
                  <li>
                    <i className="fas fa-map-marker-alt" />
                    Mobile
                  </li>
                  <li>
                    <i className="fas fa-clock" />
                    Mon - Fri: 8:00 - 18:00 Sat: by appointment Sun: Closed
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-auto">
              <div className="header-links ps-0">
                <ul>
                  <li>
                    <div className="social-links">
                      {/* <Link to="https://www.facebook.com/">
                        <i className="fab fa-facebook-f" />
                      </Link> */}
                      <a
                        rel="noopener noreferrer"
                        href="https://www.instagram.com/dentprofessionalsolution?igsh=b2xjZjNyNWJpbDJt"
                    target="_blank"  >
                        <i className="fab fa-instagram" />
                      </a>
                      {/* <Link to="https://www.twitter.com/">
                        <i className="fab fa-twitter" />
                      </Link>
                      <Link to="https://www.linkedin.com/">
                        <i className="fab fa-linkedin" />
                      </Link> */}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`sticky-wrapper ${scroll && "sticky"}`}>
        {/* Main Menu Area */}
        <div className="menu-area">
          <div className="header-navbar-logo">
            <Link to="/">
              <img
                src="assets/img/logo-white.png"
                alt="DentProfessionalSolution"
              />
            </Link>
          </div>
          <div className="container">
            <div className="row align-items-center justify-content-lg-start justify-content-between">
              <div className="col-auto d-xl-none d-block">
                <div className="header-logo">
                  <Link to="/">
                    <img
                      src="assets/img/logo-white.png"
                      alt="DentProfessionalSolution"
                    />
                  </Link>
                </div>
              </div>
              <div className="col-auto">
                <nav className="main-menu d-none d-lg-inline-block">
                  <ul>
                    <li>
                      <Link
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        to="/"
                      >
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="about"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                      >
                        About
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="service"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                      >
                        Service
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="gallery"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                      >
                        Gallery
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="contact"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                      >
                        Contact
                      </Link>
                    </li>
                  </ul>
                </nav>
                <div className="navbar-right d-inline-flex d-lg-none">
                  <button
                    type="button"
                    className="menu-toggle icon-btn"
                    onClick={mobileMenu}
                  >
                    <i className="fas fa-bars" />
                  </button>
                </div>
              </div>
              <div className="col-auto ms-auto d-xl-block d-none">
                <div className="header-button">
                  <div className="navbar-right-desc d-xxl-flex d-none">
                    <img
                      src="assets/img/icon/chat.svg"
                      alt="DentProfessionalSolution"
                    />
                    <div className="navbar-right-desc-details">
                      <h6 className="title">Need help?</h6>
                      <a className="link" href="tel:+17862103731">
                        (786) 210 3731
                      </a>
                    </div>
                  </div>
                  <a href="mailto:info@dentprofessionalsolution.com" className="btn style-border3">
                    Get a Quote
                    
                     <i className="fas fa-arrow-right ms-2" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="logo-bg" />
        </div>

        {/* Mobile Menu */}
        <div className={`mobile-menu-wrapper  ${active && "body-visible"}`}>
          <div className="mobile-menu-area">
            <div className="mobile-logo">
              <Link to="/">
                <img src="assets/img/logo.svg" alt="DentProfessionalSolution" />
              </Link>
              <button className="menu-toggle" onClick={mobileMenu}>
                <i className="fa fa-times" />
              </button>
            </div>
            <div className="mobile-menu">
              <ul id="offcanvas-navigation">
                <li>
                  <Link
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    to="/"
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="about"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                  >
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    to="service"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                  >
                    Service
                  </Link>
                </li>
                <li>
                  <Link
                    to="galery"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                  >
                    Gallery
                  </Link>
                </li>

                <li>
                  <Link
                    to="contact"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderThree;
