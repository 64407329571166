import React from "react";
import TrackVisibility from "react-on-screen";
import CountUp from "react-countup";
const FaqAreaTwo = () => {
  return (
    <section className="faq-area-2 space" id="faq">
      <div className="container">
        <div className="row gx-60 flex-row-reverse">
          <div className="col-xl-6">
            <div className="faq-thumb2 mb-xl-0 mb-50">
              <div className="about-counter-grid jump">
                <img src="assets/img/icon/faq2-counter-icon-1.svg" alt="DentProfessionalSevice"/>
                <div className="media-right">
                  <h3 className="about-counter">
                    <TrackVisibility once>
                      {({ isVisible }) =>
                        isVisible && (
                          <span className="counter-number">
                            <CountUp delay={0} start={0} end={250} />+
                          </span>
                        )
                      }
                    </TrackVisibility>
                  </h3>
                  <h4 className="about-counter-text">Services we provide</h4>
                </div>
              </div>
              <img src="assets/img/normal/faq-thumb-2-1.png" alt="DentProfessionalSevice"/>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="title-area">
              <span className="sub-title">Answers to Your Top Questions</span>
              <h2 className="sec-title">Find Everything You Need to Know Here.{" "}
                <img
                  className="title-bg-shape"
                  src="assets/img/bg/title-bg-shape.png"
                  alt="DentProfessionalSolution"
                />
              </h2>
            </div>
            <div className="accordion-area accordion" id="faqAccordion">
              <div className="accordion-card style2 active">
                <div className="accordion-header" id="collapse-item-1">
                  <button
                    className="accordion-button "
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse-1"
                    aria-expanded="true"
                    aria-controls="collapse-1"
                  >
                    {" "}
                    How long does it take to repair a dent?
                  </button>
                </div>
                <div
                  id="collapse-1"
                  className="accordion-collapse collapse show"
                  aria-labelledby="collapse-item-1"
                  data-bs-parent="#faqAccordion"
                >
                  <div className="accordion-body">
                    <p className="faq-text">
                    Repair times vary depending on the severity of the dent. Minor dents can often be fixed within a few hours, while more extensive damage may require additional time.
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="accordion-card style2">
                <div className="accordion-header" id="collapse-item-2">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse-2"
                    aria-expanded="false"
                    aria-controls="collapse-2"
                  >
                    {" "}
                    Do you offer a warranty on your services?
                  </button>
                </div>
                <div
                  id="collapse-2"
                  className="accordion-collapse collapse "
                  aria-labelledby="collapse-item-2"
                  data-bs-parent="#faqAccordion"
                >
                  <div className="accordion-body">
                    <p className="faq-text">
                    Yes, we offer a lifetime warranty on our dent repair services for your peace of mind. Our goal is to ensure your satisfaction and the longevity of our repairs.
                    </p>
                  </div>
                </div>
              </div> */}
              <div className="accordion-card style2">
                <div className="accordion-header" id="collapse-item-3">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse-3"
                    aria-expanded="false"
                    aria-controls="collapse-3"
                  >
                    {" "}
                    Will my insurance cover the cost of dent repair?
                  </button>
                </div>
                <div
                  id="collapse-3"
                  className="accordion-collapse collapse "
                  aria-labelledby="collapse-item-3"
                  data-bs-parent="#faqAccordion"
                >
                  <div className="accordion-body">
                    <p className="faq-text">
                    Many insurance policies cover the cost of dent repair, especially if the damage was caused by a covered event like a collision or hailstorm. We can assist you with the insurance claim process.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-card style2">
                <div className="accordion-header" id="collapse-item-4">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse-4"
                    aria-expanded="false"
                    aria-controls="collapse-4"
                  >
                    {" "}
                    Do you use environmentally friendly products and techniques?
                  </button>
                </div>
                <div
                  id="collapse-4"
                  className="accordion-collapse collapse "
                  aria-labelledby="collapse-item-4"
                  data-bs-parent="#faqAccordion"
                >
                  <div className="accordion-body">
                    <p className="faq-text">
                    Yes, we are committed to using eco-friendly products and techniques whenever possible. Our paintless dent repair method, for example, minimizes the need for harsh chemicals and reduces waste.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-card style2">
                <div className="accordion-header" id="collapse-item-5">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse-5"
                    aria-expanded="false"
                    aria-controls="collapse-5"
                  >
                    {" "}
                    Can you repair dents on any type of vehicle?
                  </button>
                </div>
                <div
                  id="collapse-5"
                  className="accordion-collapse collapse "
                  aria-labelledby="collapse-item-5"
                  data-bs-parent="#faqAccordion"
                >
                  <div className="accordion-body">
                    <p className="faq-text">
                    Yes, our skilled technicians are trained to repair dents on all makes and models of vehicles, including cars, trucks, SUVs, and motorcycles.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-card style2">
                <div className="accordion-header" id="collapse-item-6">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse-6"
                    aria-expanded="false"
                    aria-controls="collapse-6"
                  >
                    {" "}
                    How do I schedule an appointment for dent repair?
                  </button>
                </div>
                <div
                  id="collapse-6"
                  className="accordion-collapse collapse "
                  aria-labelledby="collapse-item-6"
                  data-bs-parent="#faqAccordion"
                >
                  <div className="accordion-body">
                    <p className="faq-text">
                    Scheduling an appointment is easy! You can call us at (786) 210 3731 or use our online booking system on our website to choose a convenient date and time for your repair.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqAreaTwo;
