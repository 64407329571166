import React from "react";
import { Link } from "react-scroll";

const ServiceAreaOne = () => {
  return (
    <div
      className="service-area-1 space-top bg-smoke overflow-hidden"
      id="service"
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="title-area text-center">
              <span className="sub-title">Service</span>
              <h2 className="sec-title">Expert Services for a Flawless Ride</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row gy-4 justify-content-center">
          <div className="col-lg-4 col-md-6">
            <div className="service-card">
              <div className="service-card_content">
                <div className="service-card_icon">
                  <img
                    src="assets/img/icon/service-icon_1-1.svg"
                    alt="DentProfessionalSevice"
                  />
                </div>
                <h4 className="service-card_title h5">
                 Paintless Dent Repair (PDR)
                </h4>
                <p className="service-card_text">
                  Restore Your Vehicle's Beauty Without Compromising Its
                  Original Paint. Our Expert Technicians Utilize Specialized
                  Techniques to Smooth Out Dents and Dings, Leaving Your Car
                  Looking Flawless.{" "}
                </p>
                <Link
                  to="contact"
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-70}
                  className="link-btn"
                >
                  Call us <i className="fas fa-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="service-card">
              <div className="service-card_content">
                <div className="service-card_icon">
                  <img
                    src="assets/img/icon/service-icon_1-2.svg"
                    alt="DentProfessionalSevice"
                  />
                </div>
                <h4 className="service-card_title h5">
                Hail Damage Repair
                </h4>
                <p className="service-card_text">
                  Don't Let Hailstorms Ruin Your Ride. Our Skilled Team
                  Specializes in Repairing Hail Damage, Restoring Your Vehicle's
                  Exterior to Its Pristine Condition.{" "}
                </p>
                <Link
                  to="contact"
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-70}
                  className="link-btn"
                >
                  Call us <i className="fas fa-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="service-card">
              <div className="service-card_content">
                <div className="service-card_icon">
                  <img
                    src="assets/img/icon/service-icon_1-3.svg"
                    alt="DentProfessionalSevice"
                  />
                </div>
                <h4 className="service-card_title h5">
                  <Link to="/service-details">Bumper Repair</Link>
                </h4>
                <p className="service-card_text">
                  From Minor Scratches to Major Collisions, We've Got You
                  Covered. Our Bumper Repair Service Ensures a Seamless Fix, So
                  You Can Hit the Road with Confidence.{" "}
                </p>
                <Link
                  to="contact"
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-70}
                  className="link-btn"
                >
                  Call us <i className="fas fa-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="service-card">
              <div className="service-card_content">
                <div className="service-card_icon">
                  <img
                    src="assets/img/icon/service-icon_1-4.svg"
                    alt="DentProfessionalSevice"
                  />
                </div>
                <h4 className="service-card_title h5">
                Paint Touch-Ups
                </h4>
                <p className="service-card_text">
                  Say Goodbye to Unsightly Paint Chips and Scratches. Our Paint
                  Touch-Up Service Restores Your Vehicle's Finish, Enhancing Its
                  Appearance and Protecting Against Rust.{" "}
                </p>
                <Link
                  to="contact"
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-70}
                  className="link-btn"
                >
                  Call us <i className="fas fa-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="service-card">
              <div className="service-card_content">
                <div className="service-card_icon">
                  <img
                    src="assets/img/icon/service-icon_1-5.svg"
                    alt="DentProfessionalSevice"
                  />
                </div>
                <h4 className="service-card_title h5">
                
                    Wheel Repair and Refinishing
                  
                </h4>
                <p className="service-card_text">
                  Keep Your Wheels Looking Sharp and Performing Smoothly. Our
                  Wheel Repair and Refinishing Service Corrects Damage and
                  Imperfections, Giving Your Vehicle That Extra Shine.{" "}
                </p>
                <Link
                  to="contact"
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-70}
                  className="link-btn"
                >
                  Call us <i className="fas fa-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="service-card">
              <div className="service-card_content">
                <div className="service-card_icon">
                  <img
                    src="assets/img/icon/service-icon_1-6.svg"
                    alt="DentProfessionalSevice"
                  />
                </div>
                <h4 className="service-card_title h5">
                Headlight Restoration
                </h4>
                <p className="service-card_text">
                  Illuminate the Road Ahead with Clarity. Our Headlight
                  Restoration Service Removes Cloudiness and Oxidation,
                  Restoring Optimal Visibility and Safety for Your Journeys.{" "}
                </p>
                <Link
                  to="contact"
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-70}
                  className="link-btn"
                >
                  Call us <i className="fas fa-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceAreaOne;
