import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, FreeMode, Thumbs, EffectFade } from "swiper";
const PortfolioTwo = () => {
  return (
    <div className="portfolio-area-1 space overflow-hidden" id="gallery">
      <div className="container">
        <div className="row justify-content-between align-items-end">
          <div className="col-xl-5 col-lg-6">
            <div className="title-area">
              <span className="sub-title">Gallery</span>
              <h2 className="sec-title">
                Affordable Car Repair The Best Solutions{" "}
                <img
                  className="title-bg-shape"
                  src="assets/img/bg/title-bg-shape.png"
                  alt="dentProfessionalSolution"
                />
              </h2>
            </div>
          </div>
          <div className="col-sm-auto">
            <div className="title-area">
              <div className="icon-box">
                <button className="slick-arrow default portfolio-button-next">
                  <i className="fas fa-arrow-left" />
                </button>
                <button className="slick-arrow default portfolio-button-prev">
                  <i className="fas fa-arrow-right" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid p-0">
        <div
          className="row global-carousel gx-30 portfolio-slider1"
          style={{ height: "850px" }}
        >
          <Swiper
            loop={true}
            navigation={{
              nextEl: ".portfolio-button-next",
              prevEl: ".portfolio-button-prev",
            }}
            spaceBetween={30}
            centeredSlides
            slidesPerView={1}
            slidesPerGroup={1}
            speed={2000}
            pagination={{ clickable: true }}
            autoplay={{ delay: 6000 }}
            className="mySwiper"
            modules={[FreeMode, Navigation, Thumbs, EffectFade]}
            breakpoints={{
              0: {
                slidesPerView: 1,
                slidesPerGroup: 1,
              },
              768: {
                slidesPerView: 2,
              },
              992: {
                slidesPerView: 3,
              },
              1200: {
                slidesPerView: 3,
              },
              1400: {
                slidesPerView: 3,
              },
            }}
          >
            <SwiperSlide>
              <iframe
                width="100%"
                height="100%"
                src="https://www.instagram.com/p/CG9CusJLZYT/embed"
                title="Instagram Post"
                frameborder="0"
              ></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="100%"
                height="100%"
                src="https://www.instagram.com/p/CG9CGesLSxT/embed"
                title="Instagram Post"
                frameborder="0"
              ></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="100%"
                height="100%"
                src="https://www.instagram.com/p/CG9B5QlLyu_/embed"
                title="Instagram Post"
                frameborder="0"
              ></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="100%"
                height="100%"
                src="https://www.instagram.com/p/CG9BAaWrQO1/embed"
                title="Instagram Post"
                frameborder="0"
              ></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="100%"
                height="100%"
                src="https://www.instagram.com/p/CG9AtaYLXGj/embed"
                title="Instagram Post"
                frameborder="0"
              ></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="100%"
                height="100%"
                src="https://www.instagram.com/p/CG8_luLrfz2/embed"
                title="Instagram Post"
                frameborder="0"
              ></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="100%"
                height="100%"
                src="https://www.instagram.com/p/CG86LdqrVfn/embed"
                title="Instagram Post"
                frameborder="0"
              ></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="100%"
                height="100%"
                src="https://www.instagram.com/p/CAizDSqgQL7/embed"
                title="Instagram Post"
                frameborder="0"
              ></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="100%"
                height="100%"
                src="https://www.instagram.com/p/CAiyzBXgtcC/embed"
                title="Instagram Post"
                frameborder="0"
              ></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="100%"
                height="100%"
                src="https://www.instagram.com/p/CAiyI0xAeZ2/embed"
                title="Instagram Post"
                frameborder="0"
              ></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="100%"
                height="100%"
                src="https://www.instagram.com/p/CAix2W9ggBd/embed"
                title="Instagram Post"
                frameborder="0"
              ></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="100%"
                height="100%"
                src="https://www.instagram.com/p/CAiw9rggKLz/embed"
                title="Instagram Post"
                frameborder="0"
              ></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="100%"
                height="100%"
                src="https://www.instagram.com/p/CAiwgBugcim/embed"
                title="Instagram Post"
                frameborder="0"
              ></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="100%"
                height="100%"
                src="https://www.instagram.com/p/B9SpHP-A6Ap/embed"
                title="Instagram Post"
                frameborder="0"
              ></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="100%"
                height="100%"
                src="https://www.instagram.com/p/B9SnT9-gaiD/embed"
                title="Instagram Post"
                frameborder="0"
              ></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="100%"
                height="100%"
                src="https://www.instagram.com/p/B9SnDSEg6aQ/embed"
                title="Instagram Post"
                frameborder="0"
              ></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="100%"
                height="100%"
                src="https://www.instagram.com/p/B9ReiNRAbvP/embed"
                title="Instagram Post"
                frameborder="0"
              ></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="100%"
                height="100%"
                src="https://www.instagram.com/p/B6GfEShhYOD/embed"
                title="Instagram Post"
                frameborder="0"
              ></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="100%"
                height="100%"
                src="https://www.instagram.com/p/B6GefRbB6Td/embed"
                title="Instagram Post"
                frameborder="0"
              ></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="100%"
                height="100%"
                src="https://www.instagram.com/p/B6GeS7YB0bf/embed"
                title="Instagram Post"
                frameborder="0"
              ></iframe>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default PortfolioTwo;
