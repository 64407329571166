import React from "react";

const FeatureAreaOne = () => {
  return (
    <div className="feature-area-1">
      <div className="container">
        <div className="row gy-4 justify-content-center">
          <div className="col-xl-4">
            <div className="single-feature-wrap">
              <div className="thumb">
                <img src="assets/img/icon/service-icon_1-4.svg" alt="DentProfessionalSevice" />
              </div>
              <div className="media-body">
                <h4>Mobile Unit </h4>
                <p>Free estimates!</p>
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="single-feature-wrap">
              <div className="thumb">
                <img src="assets/img/icon/service-icon_1-2.svg" alt="DentProfessionalSevice" />
              </div>
              <div className="media-body">
                <h4>Moneyback Guarantee</h4>
                <p>Always make sure your money!</p>
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="single-feature-wrap">
              <div className="thumb">
                <img src="assets/img/icon/service-icon_1-3.svg" alt="DentProfessionalSevice" />
              </div>
              <div className="media-body">
                <h4>Support</h4>
                <p>We always listen your questions!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureAreaOne;
