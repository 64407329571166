import React from "react";
import { Link } from "react-scroll";

const FooterAreaOne = () => {
  return (
    <footer
      className="footer-wrapper footer-layout1"
      style={{ backgroundImage: "url(assets/img/bg/footer-bg1-1.png)" }}
    >
      <div className="container">
        <div className="widget-area">
          <div className="row justify-content-between">
            <div className="col-md-6 col-xl-auto">
              <div className="widget widget_nav_menu footer-widget">
                <h3 className="widget_title">Company</h3>
                <div className="menu-all-pages-container">
                  <ul className="menu">
                    <li>
                    <Link to="about" spy={true} smooth={true} offset={-70} duration={500} >About</Link>
                    </li>
                    <li>
                    <Link to="gallery" spy={true} smooth={true} offset={-70} duration={500} >Gallery</Link>
                    </li>
                    <li>
                    <Link to="faq" spy={true} smooth={true} offset={-70} duration={500} >Faq</Link>
                    </li>
                    <li>
                    <Link to="contact" spy={true} smooth={true} offset={-70} duration={500} >Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-auto">
              <div className="widget widget_nav_menu footer-widget">
                <h3 className="widget_title">Services</h3>
                <div className="menu-all-pages-container">
                  <ul className="menu">
                    <li>
                      <Link to="service" spy={true} smooth={true} offset={-70} duration={500} >Seamless Paint Restoration</Link>
                    </li>
                    <li>
                    <Link to="service" spy={true} smooth={true} offset={-70} duration={500} >Dent-Free Perfection</Link>
                    </li>
                    <li>
                    <Link to="service" spy={true} smooth={true} offset={-70} duration={500} >Hail Damage Restoration</Link>
                    </li>
                    <li>
                    <Link to="service" spy={true} smooth={true} offset={-70} duration={500} >Bumper Beautification</Link>
                    </li>
                    <li>
                    <Link to="service" spy={true} smooth={true} offset={-70} duration={500} >Wheel Renewal and Refurbishment</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-auto">
              <div className="widget footer-widget">
                <h3 className="widget_title">Contact</h3>
                <div className="widget-contact">
                  <p>
                    <a href="tel:+17862103731">(786) 210 3731</a>
                  </p>
                  <p>
                    <a href="mailto:info@dentprofessionalsolution.com">info@dentprofessionalsolution.com</a>
                  </p>
                  <p>
                  Mobile
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="col-md-6 col-xl-auto">
              <div className="widget footer-widget widget-newsletter">
                <h3 className="widget_title">Get In Touch</h3>
                <p className="footer-text mb-50">
                  Lorem Ipsum is simply is dumiomy is text Lorem Ipsum is simply
                </p>
                <form className="newsletter-form">
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Your Email Address"
                      required=""
                    />
                  </div>
                  <button type="submit" className="btn">
                    <i className="fas fa-arrow-right" />
                  </button>
                </form>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="copyright-wrap">
        <div className="container">
          <div className="row gy-3 justify-content-md-between justify-content-center">
            <div className="col-auto align-self-center">
              <p className="copyright-text text-center">
                © <Link to="/">Dent Professional Solution</Link> 2024 | All Rights Reserved
              </p>
            </div>
            <div className="col-auto">
              <div className="footer-links">
              <Link to="contact" spy={true} smooth={true} offset={-70} duration={500} >Tarms &amp; Condition</Link>
              <Link to="contact" spy={true} smooth={true} offset={-70} duration={500} >Privacy Policy</Link>
              <Link to="contact" spy={true} smooth={true} offset={-70} duration={500} >Contact Us</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterAreaOne;
